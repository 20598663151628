import firebase from 'firebase/app';

require("firebase/auth")
require("firebase/storage")
require("firebase/firestore")
require("firebase/functions")
require("firebase/analytics")
require("firebase/performance")

// firebase init goes here
const firebaseConfig = {
  apiKey: "AIzaSyAXd73KqwvgyNRTi8L1Uik3A5ko2GlUIh4",
  authDomain: "mcaliber-bb61e.firebaseapp.com",
  projectId: "mcaliber-bb61e",
  storageBucket: "mcaliber-bb61e.appspot.com",
  messagingSenderId: "572363595049",
  appId: "1:572363595049:web:93aa8b568ed38ce7ebeb38",
  measurementId: "G-RR69R3VT6H"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics()
firebase.performance()
firebase.firestore().enablePersistence()

const db = firebase.firestore()
const firestore = firebase.firestore
const auth = firebase.auth()
const storageRef = firebase.storage().ref()
const currentUser = auth.currentUser
const functions = firebase.functions()

// firebase collections
const usersCollection = db.collection('users')
const ordersCollection = db.collection('orders')
const productsCollection = db.collection('products')
const customersCollection = db.collection('customers')
const categoriesCollection = db.collection('categories')

export {
  db,
  auth,
  storageRef,
  firestore,
  currentUser,
  functions,
  usersCollection,
  ordersCollection,
  productsCollection,
  customersCollection,
  categoriesCollection
}