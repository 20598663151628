import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
const fb = require('../firebaseConfig.js')
Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile')
    store.dispatch('fetchCustomerProfile')
    } else {
    store.commit('setCurrentUser', null)
  }
})

export const store = new Vuex.Store({
  state: {
  	currentUser: null,
    userProfile: {},
    customerProfile: {},
    products: [],
    product:{},
    vendorProducts: [],
    customerOrders: [],
    order: {},
    customers: [],
    customer: {},
    orders: [],
    categories: []
  },
  actions: {
  	async logout({ commit, dispatch }) {
      await fb.auth.signOut()
      dispatch('clearData')
      router.push('/')
    },
    fetchUserProfile({ commit, state }) {
      fb.usersCollection.doc(state.currentUser.uid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          commit('setUserProfile', doc.data())
          store.dispatch('getCustomers')
          store.dispatch('getOrders')
        } else {
        }
      })
    },
    fetchCustomerProfile({ commit, state }) {
      fb.customersCollection.doc(state.currentUser.uid)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          commit('setCustomerProfile', doc.data())
          store.dispatch('getCustomerOrders', doc.data().id)
        } else {
        }
      })
    },
    getCustomerOrders({ commit }, payload) {
    	fb.ordersCollection.where("customer.id", "==", payload).orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let ordersArray = []
        querySnapshot.forEach(doc => {
          let order = doc.data()
          order.id = doc.id
          ordersArray.push(order)
        })
        commit('setCustomerOrders', ordersArray)
      })
    },
    getProducts({ commit }) {
      fb.productsCollection.orderBy('displayOrder', 'asc').orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let productsArray = []
        querySnapshot.forEach(doc => {
          let product = doc.data()
          product.id = doc.id
          productsArray.push(product)
        })
        commit('setProducts', productsArray)
      })
    },
    getOrders({ commit }) {
      fb.ordersCollection.orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let ordersArray = []
        querySnapshot.forEach(doc => {
          let order = doc.data()
          order.id = doc.id
          ordersArray.push(order)
        })
        commit('setOrders', ordersArray)
      })
    },
    getCategories({ commit }) {
      fb.categoriesCollection.orderBy('order', 'asc').onSnapshot(querySnapshot => {
        let categoriesArray = []
        querySnapshot.forEach(doc => {
          let category = doc.data()
          category.id = doc.id
          categoriesArray.push(category)
        })
        commit('setCategories', categoriesArray)
      })
    },
    clearCategories({ commit }) {
      commit('setCategories', null)
    },
    addProduct({ commit }, payload) {
    	fb.productsCollection.add(payload).then(
        doc => {
        fb.productsCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
          unitOfMeasure: 'Each',
          quantityOfMeasure: 1
        })
      })
    },
    getProductFromId({ commit }, payload) {
      fb.productsCollection.where("id", "==", payload)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          commit("setProduct", doc.data())
        })
      })
    },
    getProductsFromVendor({ commit }, payload) {
      fb.productsCollection.where("manufacturer", "==", payload).orderBy('displayOrder', 'asc').orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let productsArray = []
        querySnapshot.forEach(doc => {
          let products = doc.data()
          products.id = doc.id
          productsArray.push(products)
        })
        commit('setVendorProducts', productsArray)
      })
    },
    updateProduct({ commit }, payload) {
      fb.productsCollection.doc(payload.id).update(payload)
    },
    newOrder({ commit }, payload) {
    	console.log(payload)
    	let customer = payload.customer
    	fb.ordersCollection.add({
    		customer: customer,
    		order: payload.order
    	}).then(
        doc => {
        fb.ordersCollection.doc(doc.id).update({
          id: doc.id,
          created: fb.firestore.FieldValue.serverTimestamp(),
          status: 'New'
        })
      })
    },
    getOrderFromId({ commit }, payload) {
      fb.ordersCollection.where("id", "==", payload)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          commit("setOrder", doc.data())
        })
      })
    },
    updateOrder({ commit }, payload) {
      fb.ordersCollection.doc(payload.id).update(payload)
    },
    getCustomers({ commit }) {
      fb.customersCollection.orderBy('created', 'desc').onSnapshot(querySnapshot => {
        let customersArray = []
        querySnapshot.forEach(doc => {
          let customer = doc.data()
          customer.id = doc.id
          customersArray.push(customer)
        })
        commit('setCustomers', customersArray)
      })
    },
    getCustomerFromId({ commit }, payload) {
      fb.customersCollection.where("id", "==", payload)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          commit("setCustomer", doc.data())
        })
      })
    },
    clearOrder({ commit }) {
    	commit('setOrder', null)
    },
    clearCustomer({ commit }) {
    	commit('setCustomer', null)
    },
    clearProduct({ commit }) {
    	commit('setProduct', null)
    },
    clearVendor({ commit }) {
    	commit('setVendorProducts', null)
    },
    clearData({ commit }) {
      commit('setCurrentUser', null)
      commit('setUserProfile', null)
      commit('setCustomerProfile', null)
      commit('setCustomerOrders', [])
      commit('setCustomers', [])
    }
  },
  mutations: {
  	setVendorProducts(state, val) {
      if (val) {
        state.vendorProducts = val
      } else {
        state.vendorProducts = []
      }
    },
  	setProducts(state, val) {
      if (val) {
        state.products = val
      } else {
        state.products = []
      }
    },
    setCustomerOrders(state, val) {
      if (val) {
        state.customerOrders = val
      } else {
        state.customerOrders = []
      }
    },
    setCustomers(state, val) {
      if (val) {
        state.customers = val
      } else {
        state.customers = []
      }
    },
    setOrders(state, val) {
      if (val) {
        state.orders = val
      } else {
        state.orders = []
      }
    },
    setCategories(state, val) {
      if (val) {
        state.categories = val
      } else {
        state.categories = []
      }
    },
  	setCurrentUser(state, val) {
      state.currentUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setCustomerProfile(state, val) {
      state.customerProfile = val
    },
    setProduct(state, val) {
      state.product = val
    },
    setOrder(state, val) {
      state.order = val
    },
    setCustomer(state, val) {
      state.customer = val
    },
  },
})
